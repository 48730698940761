<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>
        <div class='d-block position-relative'>
            <Multiselect
                :options='this.options'
                v-model='selectedOption'
                v-bind='itemBody.multiselect'
                @input='input'
            />
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import FormItemMixin from './mixins/FormItemMixin';
import Multiselect from 'vue-multiselect';

export default {
    name: 'FormMultiSelect',
    mixins: [FormItemMixin],
    components: {Multiselect},
    data() {
        return {
            selectedOption: null,
        }
    },
    created() {
        this.selectedOption = this.value;
    },
    methods: {
        input(val) {
            console.log(val);
            if (this.validation) {
                const validation = this.validation.validate(val);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', val);
        },
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>