import axios from 'axios';

export function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
    };
}

export class MyUploadAdapter {
    xhr;
    loader;
    url;

    constructor(loader) {
        // CKEditor 5's FileLoader instance.
        this.loader = loader;

        // URL where to send files.
        this.url = `${process.env.VUE_APP_API_BASE_URL}/crm/ckeditor/upload-image`;
    }

    upload() {
        return this.loader.file.then((uploadedFile) => {
            return new Promise((resolve, reject) => {
                const data = new FormData();
                data.append('upload', uploadedFile);

                axios({
                    url: `${process.env.VUE_APP_API_BASE_URL}/crm/ckeditor/upload-image`,
                    method: 'post',
                    data,
                    headers: {
                        'Content-Type': 'multipart/form-data;',
                    },
                    withCredentials: false,
                })
                    .then((response) => {
                        if (response.data) {
                            resolve({
                                default: response.data.url,
                            });
                        } else {
                            reject(response.data.message);
                        }
                    })
                    .catch(() => {
                        reject('Upload failed');
                    });
            });
        });
    }

    // Aborts the upload process.
    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }
}
