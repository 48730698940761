<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>
        <div class='d-block position-relative'>
            <textarea class='form-control' v-model='text' @input='input'/>
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import FormItemMixin from './mixins/FormItemMixin';

export default {
    name: 'FormMultiSelect',
    mixins: [FormItemMixin],
    data() {
        return {
            text: null,
        }
    },
    created() {
        this.text = this.value;
    },
    methods: {}
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>