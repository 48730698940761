<template>
    <b-tabs>
        <template v-for='tab in tabList'>
            <b-tab :key='tab.title' :title="tab.title">
                <div class="row">
                    <template v-for='input in tab.value'>
                        <FormBuilderItem
                            :id="input.id"
                            :key='input.id'
                            :item='input'
                            :newValue='newValue[input.id]'
                            :setValue='setValue'
                        />
                    </template>
                </div>
            </b-tab>
        </template>
    </b-tabs>
</template>

<script>
import FormBuilderItem from './FormBuilderItem';

export default {
    name: 'FormTabs',
    components: {
        FormBuilderItem,
    },
    props: {
        tabList: {
            type: Object,
            required: true,
        },
        newValue: {
            type: Object,
            required: true,
        },
        setValue: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style scoped>

</style>
