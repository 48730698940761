<template>
    <div class='form-group'>
        <label :for='id'>{{ label }}</label>
        <div class='d-block position-relative'>
            <switcher :id='id' v-model='checked' :checked='checked' @input='input'/>
<!--            <input class='d-block' :id='id' v-model='checked' :checked='checked' type='checkbox' @change='input'>-->
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';
import Switcher from '@/components/Switcher';

export default {
    name: 'FormCheckBoxItem',
    components: { Switcher },
    mixins: [FormItemMixin],
    data() {
        return {
            checked: false,
            error: null
        }
    },
    methods: {
        input(val) {
            if (this.validation) {
                const validation = this.validation.validate(""+this.checked);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', this.checked);
        }
    },
    mounted() {
        if (this.value) {
            this.checked = true
        }
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: red;
}
label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
</style>
