<template>
    <div class="seo-field">
        <input v-model='field.key' type="text" class='form-control' @input='handleEditKey'>
        <input v-model='field.value' type="text" class='form-control' @input='handleEditValue'>

        <button
            type="button"
            class="button-icon"
            title="Удалить"
            @click="handleDelete"
        >
            <i class="fa fa-times fa-lg"></i>
        </button>
    </div>
</template>

<script>
export default {
    name: 'FormSeoField',
    props: {
        idx: {
            required: true,
        },
        item: {
            type: Object,
            required: true,
        }
    },
    computed: {
        field() {
            return { ...this.item };
        }
    },
    methods: {
        handleEditKey(key) {
            const item = { key: key.target.value, value: this.field.value };
            if (this.item.id) {
                item.id = this.field.id;
            }
            this.$emit('on-change', this.idx, item);
        },
        handleEditValue(value) {
            const item = { key: this.field.key, value: value.target.value };
            if (this.field.id) {
                item.id = this.field.id;
            }
            this.$emit('on-change', this.idx, item);
        },
        handleDelete() {
            this.$emit('delete', this.idx);
        },
    },
};
</script>

<style lang='scss' scoped>
.seo-field {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;

    input {
        width: 50%;
    }
}
.button-icon {
    background-color: rgb(189, 50, 50);
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
