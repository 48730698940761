<template>
    <div class='form-group'>
        <label class='position-relative d-block' :for='id'>{{ label }} <span class='validation-error' v-show='error'>{{ error }}</span></label>
        <b-form-datepicker :id='id'
                           v-model='local'
                           :value='value'
                           :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                           locale='ru'
                           :disabled='false'
                           :state='dateValid'
                           @input='input'
                           required>
        </b-form-datepicker>
    </div>
</template>

<script>
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';

export default {
    name: 'FormDateItem',
    mixins: [FormItemMixin],
    props: {
        id: {
            required: true
        },
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
        validation: {
            type: Object,
            required: false
        },
        value: {
            required: false,
            default: null
        }
    },
    data() {
      return {
          local: null,
          error: null
      }
    },
    computed: {
        dateValid() {
            return this.validation.validate(this.local).valid;
        },
    },
    methods: {
        input(val) {
            if (this.validation) {
                const validation = this.validation.validate(val)
                if (!validation.valid) {
                    this.error = validation.reason
                } else {
                    this.error = null
                }
            }
            this.$emit('input', val)
        }
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
//position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
