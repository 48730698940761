<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>
        <div class='d-block position-relative'>
            <select class="custom-select form__dropdown" v-model="selectedOption" @input='handleInput'>
                <option v-for="item in options" :key="item.value" :value="item.value">
                    {{ item.text }}
                </option>
            </select>
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';

export default {
    name: 'FormSelect',
    mixins: [FormItemMixin],
    data() {
        return {
            selectedOption: null,
        }
    },
    created() {
        this.selectedOption = this.value;
    },
    methods: {
        handleInput(value) {
            if (this.validation) {
                const validation = this.validation.validate(value.target.value);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.selectedOption = value.target.value;
            this.$emit('input', value.target.value);
        }
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 33px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
