<template>
    <form @submit.prevent='_save'>
        <template v-for='(input, key) in formData'>
            <FormTabs
                v-if="input.type === 'tabs'"
                :class='input.class ? input.class : "col-12"'
                :key='key + `_tabs`'
                :tabList="input.value"
                :newValue='form'
                :setValue="setValue"
            />
            <FormBuilderItem
                v-else
                :key='key'
                :id='key'
                :item='input'
                :newValue='form[key]'
                :setValue='setValue'
            />
        </template>
        <div class='col-12 text-right'>
            <button class='btn btn-success' @submit.prevent='_save'>Сохранить</button>
            <a class='btn btn-primary' v-if='saveAndCreateNew' @submit='_saveAndCreateNew'>Сохранить и создать новую</a>
            <a class='btn btn-primary' v-if='reset' @submit='_saveAndUpdate'>Сохранить и обновить</a>
            <a class='btn btn-gray' v-if='reset' @submit='_reset'>Сбросить</a>
            <a class='ml-3 btn btn-gray' @click.prevent="$router.back()">Назад</a>
        </div>
    </form>
</template>

<script>
import FormBuilderItem from './FormBuilderItem';
import FormTabs from './FormTabs';

function setFormData(values) {
    const out = {};
    for (const key in values) {
        if (values[key].type === 'tabs') {
            for (const tabsKey in values[key].value) {
                for (const inputKey in values[key].value[tabsKey].value) {
                    const input = values[key].value[tabsKey].value[inputKey];
                    out[input.id] = getFormDataValue(input.value);
                }
            }
        } else {
            out[key] = getFormDataValue(values[key].value);
        }
    }

    return out;
}

function getFormDataValue(value) {
    if (typeof value === 'boolean' && !value ||
        typeof value === 'number' && !value) {
        return value;
    } else if (typeof value === 'string' && !value) {
        return '';
    } else {
        return value ? value : null;
    }
}

export default {
    name: 'FormBuilder',
    components: {
        FormBuilderItem,
        FormTabs,
    },
    props: {
        save: {
            type: Function,
            required: false,
        },
        saveAndUpdate: {
            type: Function,
            required: false,
        },
        saveAndCreateNew: {
            type: Function,
            required: false,
        },
        reset: {
            type: Function,
            required: false,
        },
        formData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: setFormData(this.formData),
        };
    },
    computed: {
        newValue(key) {
            return this.form[key];
        },
    },
    methods: {
        setValue(key, $event) {
            this.form[key] = $event;
        },
        _saveAndUpdate() {
            return;
        },
        _saveAndCreateNew() {
            return;
        },
        _reset() {
            return;
        },
        _save(e) {
            this.save(this.form);
        },
    },
};
</script>

<style scoped>

</style>
