<template>
    <b-input-group>
        <b-input-group-prepend>
            <b-input-group-text>
                <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="clock"
                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                     class="bi-clock b-icon bi">
                    <g>
                        <path
                            d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"></path>
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"></path>
                    </g>
                </svg>
            </b-input-group-text>
        </b-input-group-prepend>
        <b-form-select ref="selector"
            class=""
            :options="timePoints"
            :value="value"
            :disabled="disabled"
            :state="state"
            @change="handleChange"
        ></b-form-select>
    </b-input-group>
</template>

<script>
import moment from 'moment';

export default {
    name: 'NormalTimePicker',
    props: {
        value: {
            required: true
        },
        disabled: {
            required: false,
            default: false
        },
        startHour: {
            type: Number,
            required: false,
            default: 0
        },
        endHour: {
            type: Number,
            required: false,
            default: 24
        },
        step: {
            type: Number,
            required: false,
            default: 30
        },
        state: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data() {
        return {
            timePoints: [
                {text: '06:00', value: '06:00'},
                {text: '06:30', value: '06:30'},
                {text: '07:00', value: '07:00'},
            ]
        }
    },
    methods: {
        handleChange(value)
        {
            this.$emit('input', value);
            this.$emit('update:value', value);
        }
    },
    created() {
        const endHour = this.endHour ? this.endHour : 24;
        const startHour = this.startHour ? this.startHour : 0;
        if (this.endHour > this.startHour) {
            const list = [];
            let time = moment(), point, formatted;
            time.set('hour', startHour);
            time.set('minute', 0);
            time.set('second', 0);
            do {
                if (point === 24) break;
                formatted = time.format('HH:mm');
                list.push({text: formatted, value: formatted});
                time.add(this.step, 'm');
                point = time.hour();
                if (time.hour() == 0 && time.minute() == 0) {
                    point = 24;
                }
            } while (point <= endHour);
            this.timePoints = list;
        }
    }
};
</script>

<style scoped lang="scss">


</style>
