import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import isMobile from 'validator/lib/isMobilePhone';
import isDate from 'validator/lib/isDate';
import isBoolean from 'validator/lib/isBoolean';

const pipes = [];

const makeSuccessMessage = () => {
    return {
        reason: null,
        valid: true,
    };
};

const makeErrorMessage = (message) => {
    return {
        reason: message,
        valid: false,
    };
};

const _isNumeric = (val) => {
    if (!val) {
        return false;
    }
    if (val.length > 1) {
        if (val.charAt(0) == 0 && !/[.]/.test(val)) {
            return false;
        }
    }
    return isNumeric(val);
};

const maxFunc = (maxCount) => {
    try {
        const max = +maxCount;
        return (val) => {
            if (isNumeric(val)) {
                return String(val).length <= max;
            }
            return val.length <= max;
        };
    } catch (e) {
        return false;
    }
};

const dateValidator = (f) => {
    const format = f;
    return (val) => {
        return isDate(val, format);
    };
};

const dateTimeValidator = (f) => {
    const format = f;
    return (val) => {
        const {date, time} = val
        const isDateValid = isDate(date, format);
        if (isDateValid) {
            if (!time) {
                return false;
            }
            // hh:mm:ss
            if (typeof time === 'object') {
                return false;
            }
            const splittedParts = time.split(':')
            if (splittedParts.length > 1) {
                for (let idx = 0; idx < splittedParts.length; idx++) {
                    if (!isNumeric(splittedParts[idx])) {
                        return false;
                    }
                    if (idx === 0) {
                        if (+splittedParts[idx] > 23) {
                            return false
                        }
                    }
                    if(+splittedParts[idx] > 59) {
                        return false
                    }
                }
                return true;
            }
        }
        return false;
    }
};

const createValidator = (validator, message) => {
    const reason = message;
    return (val) => {
        if (!validator(val)) {
            return makeErrorMessage(reason);
        }
        return makeSuccessMessage();
    };
};

const pipeLine = (value, validators = []) => {
    for (const validator of validators) {
        const validatorResult = validator(value);
        if (!validatorResult.valid) {
            return makeErrorMessage(validatorResult.reason);
        }
    }
    return makeSuccessMessage();
};

const createValidators = function(t) {
    const validators = [];
    let requiredField = false;

    if (!t) {
        return { requiredField }
    }
    
    const validatorList = t.split('|');

    for (const item of validatorList) {
        const validatorsArgs = item.split(':');
        const type = validatorsArgs[0];
        if (type === 'email') {
            validators.push(createValidator(isEmail, 'Email не правильный'));
        }
        if (type === 'numeric') {
            validators.push(createValidator(_isNumeric, 'Поле должно быть цифрой'));
        }
        if (type === 'phone') {
            validators.push(createValidator(isMobile, 'Неправильный номер'));
        }
        if (type === 'date') {
            validators.push(createValidator(dateValidator({
                format: 'yyyy-mm-dd',
                delimiters: ['.', '-'],
            }), 'Неправильный формат даты'));
        }
        if (type === 'bool') {
            validators.push(createValidator(isBoolean, "Поле должно быть да или нет"))
        }
        if (type === 'dateTime') {
            validators.push(createValidator(dateTimeValidator({
                format: 'yyyy-mm-dd',
                delimiters: ['.', '-'],
            }), 'Неправильный формат даты YYYY-MM-DD HH:MM:SS'));
        }
        if (type === 'required') {
            requiredField = true;
            validators.push(createValidator((val) => {
                    return val ? Array.isArray(val) ? val.length > 0 : 1 : 0;
                }, 'Поле не должно быть пустым'),
            );
        }
        if (type === 'max') {
            validators.push(createValidator(maxFunc(validatorsArgs[1]), `Значение не должно быть больше ${validatorsArgs[1]}`));
        }
    }

    if (validators.length) {
        return {
            validators,
            requiredField,
        };
    }
};

export default function() {
    return {
        formValid: false,
        validatorsMap: {},
        validatorCalls: 0,
        getValidator: function(type) {
            const validators = createValidators(type);
            this.validatorsMap[type] = false;
            return {
                requiredField: validators.requiredField,
                validate: (value) => {
                    if (!validators.requiredField && (!value)) {
                        this.validatorsMap[type] = true;
                        return makeSuccessMessage();
                    }
                    const validated = pipeLine(value, validators.validators);
                    this.validatorsMap[type] = false;
                    return validated;
                }
            }
        },
    };
}
