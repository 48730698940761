export default {
    props: {
        id: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
        options: {
            type: Array,
            required: false,
        },
        validation: {
            type: Object,
            required: false,
        },
        value: {
            required: false,
            default: null,
        },
        itemBody: {
            required: false,
        }
    },
    data() {
        return {
            error: null,
        };
    },
    methods: {
        input(val) {
            if (this.validation) {
                const validation = this.validation.validate(val.target.value);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', val.target.value);
        },
        inputEditor(val) {
            if (this.validation) {
                const validation = this.validation.validate(val);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', val);
        },
    },
    created() {
        if (this.validation) {
            const res = this.validation.validate(this.value);

            if (!res.valid) {
                this.error = res.reason;
            }
        }
    },
}
