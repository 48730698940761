<template>
    <div class='form-group'>
        <label class='position-relative d-block text-label' :for='id'>{{ label }} <span class='validation-error' v-show='error'>{{ error }}</span></label>
        <div class='d-flex'>
            <b-form-datepicker :id='id'
                               class='col-8'
                               v-model='date'
                               :value='value'
                               :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                               locale='ru'
                               :disabled='false'
                               :state='dateValid'
                               @input='input'
                               required>
            </b-form-datepicker>
            <NormalTimePicker class='col-4 pr-0' v-model='time' :state='dateValid' @input='input'/>
        </div>
        <div class='text-right mt-2'><a class='btn btn-reset' @click.prevent='clearData'>сбросить</a></div>
    </div>
</template>

<script>
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';
import NormalTimePicker from '@/components/NormalTimePicker';

export default {
    name: 'FormDateTimeItem',
    components: { NormalTimePicker },
    mixins: [FormItemMixin],
    props: {
        id: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
        },
        validation: {
            type: Object,
            required: false,
        },
        value: {
            required: false,
            default: null,
        },
    },
    data() {
        return {
            date: null,
            time: null,
            error: null,
        };
    },
    computed: {
        dateValid() {
            if (this.validation.requiredField) {
                return this.validation.validate({date: this.date, time: this.time}).valid;
            }
            return true;
        },
    },
    methods: {
        input(val) {
            if (this.validation) {
                const validation = this.validation.validate({date: this.date, time: this.time});
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
                this.sendEvent()
            }
        },
        sendEvent() {
            if (this.date && this.time) {
                const dateString = this.date + " " + (this.time ?? '');
                this.$emit('input', dateString);
                return
            }
            this.$emit('input', null);
        },
        clearData() {
            this.time = null;
            this.date = null;
            this.error = null;
            this.sendEvent()
        }
    },
    created() {
        if (this.value) {
            this.time = this.value.split(" ")[1].split(":").slice(0, 2).join(":")
            this.date = this.value.split(" ")[0]
            this.input()
        }
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    //position: absolute;
    font-size: 12px;
    text-transform: none;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.btn-reset {
    background: #eee;
    padding: 0 10px;
    font-size: 12px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>