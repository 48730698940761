var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticClass:"text-label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_c('div',{staticClass:"image-upload",class:{ 'dragging': _vm.isDragging },style:({ aspectRatio: _vm.itemBody.aspectRatio ? _vm.itemBody.aspectRatio : _vm.aspectRatio }),on:{"dragover":_vm.dragover,"dragleave":_vm.dragleave,"drop":_vm.dropImage}},[(_vm.url || _vm.value)?_c('img',{staticClass:"image-preview",attrs:{"src":_vm.url ? _vm.url : _vm.value,"alt":"preview"}}):_c('div',{staticClass:"image-default"},[_c('ImageDefault')],1),_c('label',{staticClass:"edit-button",on:{"mouseenter":() => _vm.isTooltipEditShown = true,"mouseleave":() => _vm.isTooltipEditShown = false}},[(_vm.url || _vm.value)?_c('i',{staticClass:"fa fa-pencil fa-lg"}):_c('i',{staticClass:"fa fa-plus fa-lg"}),_c('div',{staticClass:"tooltip",class:{
                    'tooltip-shown': _vm.isTooltipEditShown,
                    'tooltip-hidden': !_vm.isTooltipEditShown,
                }},[(_vm.url || _vm.value)?_c('span',[_vm._v("Заменить изображение")]):_c('span',[_vm._v("Добавить изображение")])]),_c('input',{staticClass:"hidden",attrs:{"type":"file","name":"image","accept":".png, .jpg, .jpeg"},on:{"change":_vm.handleInput}})]),(_vm.url || _vm.value)?_c('label',{staticClass:"delete-button",on:{"mouseenter":() => _vm.isTooltipDeleteShown = true,"mouseleave":() => _vm.isTooltipDeleteShown = false,"click":function($event){$event.preventDefault();return _vm.handleDeleteImage.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-times fa-lg"}),_c('div',{staticClass:"tooltip",class:{
                    'tooltip-shown': _vm.isTooltipDeleteShown,
                    'tooltip-hidden': !_vm.isTooltipDeleteShown,
                }},[_c('span',[_vm._v("Удалить изображение")])])]):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"validation-error"},[_vm._v(_vm._s(_vm.error))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }