<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>
        <div class='d-block position-relative'>
            <EditorWrap id="form-editor" :value="value" @input="handleInput" />
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import EditorWrap from '@/components/EditorWrap/EditorWrap.vue';
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';

export default {
    name: 'FormEditor',
    mixins: [FormItemMixin],
    components: {
        EditorWrap,
    },
    methods: {
        handleInput(value) {
            if (this.validation) {
                const validation = this.validation.validate(value);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', value);
        }
    }
};
</script>

<style lang='scss' scoped>
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 5px;
    top: 20px;
    transform: translateY(-50%);
    position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
