<template>
    <component
        :id="id"
        :class='item.class ? item.class : "col-6"'
        :is='getComponentByType(item.type)'
        :label='item.label'
        :type='item.type'
        :options='item.options'
        :value='newValue'
        :item-body='item'
        :validation='validator.getValidator(item.validation)'
        @input='setValue(id, $event)'
    />
</template>

<script>
import FormItem from './FormItem';
import FormCheckBoxItem from './FormCheckBoxItem';
import FormDateItem from './FormDateItem';
import FormDateTimeItem from './FormDateTimeItem';
import FormSelect from './FormSelect';
import FormMultiSelect from './FormMultiSelect';
import FormBuilderValidator from './FormBuilderValidator';
import FormTextArea from './FormTextArea';
import FormEditor from './FormEditor';
import FormImage from './FormImage';
import FormSeoFields from './FormSeoFields';

export default {
    name: 'FormBuilderItem',
    props: {
        id: {
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
        newValue: {
            required: false,
        },
        setValue: {
            type: Function,
            required: true,
        },
    },
    data() {
        return {
            validator: FormBuilderValidator(),
        };
    },
    methods: {
        getComponentByType(type) {
            switch (type) {
                case 'text':
                case 'password':
                case 'email':
                case 'phone':
                case 'number':
                    return FormItem;
                case 'bool':
                    return FormCheckBoxItem;
                case 'date':
                    return FormDateItem;
                case 'dateTime':
                    return FormDateTimeItem;
                case 'select':
                    return FormSelect;
                case 'multiselect':
                    return FormMultiSelect;
                case 'textarea':
                    return FormTextArea;
                case 'editor':
                    return FormEditor;
                case 'image':
                    return FormImage;
                case 'seoFields':
                    return FormSeoFields;
                default:
                    break;
            }
        },
    },
};
</script>

<style scoped>

</style>
