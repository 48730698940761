<template>
    <div class="editor-wrap">
        <div>
            <ckeditor
                v-model="editorData"
                :editor="editor"
                :config="editorConfig"
                :ref="id"
            />
        </div>
    </div>
</template>

<script>
import { Alignment } from '@ckeditor/ckeditor5-alignment'; 
import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { ClassicEditor } from '@ckeditor/ckeditor5-editor-classic';
import { Essentials } from '@ckeditor/ckeditor5-essentials';
import { Bold, Italic, Strikethrough, Subscript, Superscript, Underline } from '@ckeditor/ckeditor5-basic-styles';
import { Link } from '@ckeditor/ckeditor5-link';
import { Paragraph } from '@ckeditor/ckeditor5-paragraph';
import { Font } from '@ckeditor/ckeditor5-font';
import { List } from '@ckeditor/ckeditor5-list';
import { Heading, HeadingButtonsUI } from '@ckeditor/ckeditor5-heading';
import { Highlight } from '@ckeditor/ckeditor5-highlight';
import { HorizontalLine } from '@ckeditor/ckeditor5-horizontal-line';
import { Table, TableToolbar } from '@ckeditor/ckeditor5-table';
import { ImageInsert, Image, ImageCaption, ImageResize, ImageStyle, ImageToolbar } from '@ckeditor/ckeditor5-image';
import { MyCustomUploadAdapterPlugin } from '@/utils/ckeditor-image-upload';
import { SourceEditing } from '@ckeditor/ckeditor5-source-editing';
import { BlockQuote } from '@ckeditor/ckeditor5-block-quote';
import { Style } from '@ckeditor/ckeditor5-style';

const defaultConfig = {
    plugins: [
        Alignment,
        Font,
        Essentials,
        Bold,
        BlockQuote,
        Italic,
        Underline,
        Strikethrough,
        Link,
        List,
        Subscript,
        Superscript,
        Paragraph,
        Heading,
        HeadingButtonsUI,
        Highlight,
        HorizontalLine,
        GeneralHtmlSupport,
        Table,
        TableToolbar,
        Image,
        ImageToolbar,
        ImageCaption,
        ImageStyle,
        ImageResize,
        ImageInsert,
        SourceEditing,
        Style,
    ],
    toolbar: {
        items: [
            'heading2',
            'heading3',
            'heading4',
            'fontSize',
            'fontColor',
            'fontBackgroundColor',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'link',
            'alignment',
            'insertImage',
            'bulletedList',
            'numberedList',
            'undo',
            'redo',
            'insertTable',
            'highlight',
            'horizontalLine',
            'blockQuote',
            'style',
            'sourceEditing',
        ],
        shouldNotGroupWhenFull: true,
    },
    list: {
        properties: {
            styles: true,
            startIndex: true,
        },
    },
    image: {
        toolbar: [
            'toggleImageCaption',
            'imageTextAlternative',
        ],
    },
    htmlSupport: {
        allow: [
            {
                name: /.*/,
                attributes: true,
                classes: true,
                styles: true,
            },
        ],
    },
    heading: {
        options: [
            {
                model: 'heading2',
                view: { name: 'h2', classes: '' }, // в classes подкидывать кастомные классы
                title: 'Heading 2',
                class: 'ck-heading_heading2',
            },
            {
                model: 'heading3',
                view: { name: 'h3', classes: '' }, // в classes подкидывать кастомные классы
                title: 'Heading 3',
                class: 'ck-heading_heading3',
            },
            {
                model: 'heading4',
                view: { name: 'h4', classes: '' }, // в classes подкидывать кастомные классы
                title: 'Heading 4',
                class: 'ck-heading_heading4',
            },
        ],
    },
    style: {
        definitions: [
            {
                name: 'Info quote',
                element: 'blockquote',
                classes: [ 'info-quote' ]
            },
            {
                name: 'Warning quote',
                element: 'blockquote',
                classes: [ 'warning-quote' ]
            },
            {
                name: 'Alert quote',
                element: 'blockquote',
                classes: [ 'alert-quote' ]
            },
            {
                name: 'Рекламный блок в мобильной версии',
                element: 'p',
                classes: [ 'social-ad' ]
            },
        ]
    },
    extraPlugins: [MyCustomUploadAdapterPlugin],
};

export default {
    name: 'EditorWrap',
    props: {
        id: {
            type: String,
            required: true
        },
        nameClass: {
            type: String,
            default: null,
        },
        value: {
            type: String,
            default: '',
        },
        config: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            editor: ClassicEditor,
        }
    },
    computed: {
        editorData: {
            get() {
                return this.value ? this.value : '';
            },
            set(value) {
                this.$emit('input', value);
            } 
        },
        editorConfig() {
            return Object.assign({}, defaultConfig, this.config);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .ck.ck-content blockquote.info-quote,
    .ck.ck-content blockquote.warning-quote,
    .ck.ck-content blockquote.alert-quote {
        position: relative;
        padding: 20px 32px 32px 76px;
        border-radius: 8px;
        border-left: 0;
        margin: 16px 0;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 20px;
            top: 20px;
            width: 40px;
            height: 40px;
        }
    }

    .ck.ck-content blockquote.info-quote {
        background-color: #E4F6FF;

        &:before {
            background-image: url('../../assets/icons/info.svg');
        }
    }
    .ck.ck-content blockquote.warning-quote {
        background-color: #FFF1E4;

        &:before {
            background-image: url('../../assets/icons/warning.svg');
        }
    }
    .ck.ck-content blockquote.alert-quote {
        background-color: #FFE4E4;

        &:before {
            background-image: url('../../assets/icons/alert.svg');
        }
    }

    .ck.ck-content p.social-ad {
        background-image: url('../../assets/social.png');
        width: 343px;
        height: 383px;
    }
}
</style>
