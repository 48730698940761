<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>
        <div class='d-block position-relative'>
            <div class="seo-fields">
                <div class="seo-fields-head">
                    <div>
                        <p>Key</p>
                    </div>

                    <div>
                        <p>Value</p>
                    </div>

                    <button
                        type="button"
                        class="button-icon"
                        title="Добавить"
                        @click="handleAdd"
                    >
                        <i class="fa fa-plus fa-lg"></i>
                    </button>
                </div>

                <FormSeoField
                    v-for="(item, idx) in value"
                    :key="idx"
                    :idx="idx"
                    :item="item"
                    @on-change="handleChange"
                    @delete="handleDelete"
                />
            </div>
            
            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>
    </div>
</template>

<script>
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';
import FormSeoField from '@/components/FormBuilder/FormSeoField';

export default {
    name: 'FormSeoFields',
    mixins: [FormItemMixin],
    components: {
        FormSeoField,
    },
    methods: {
        handleAdd() {
            this.value.push({ key: '', value: '' });
            this.$emit('input', this.value);
        },
        handleChange(idx, value) {
            this.value[idx] = value;
            this.$emit('input', this.value);
        },
        handleDelete(idx) {
            this.value.splice(idx, 1);
            this.$emit('input', this.value);
        }
    },
};
</script>

<style lang='scss' scoped>
.seo-fields {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &-head {
        position: relative;
        display: flex;
        align-items: center;
        gap: 24px;

        & > div {
            width: 50%;

            p {
                color: rgb(71, 69, 69);
                text-transform: uppercase;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
    }
}
.validation-error {
    color: #fff;
    font-weight: bold;
    right: 5px;
    top: 20px;
    transform: translateY(-50%);
    position: absolute;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}

.button-icon {
    background-color: #009EF7;
}

::v-deep {
    .button-icon {
        padding: 6px 10px;
        height: fit-content;
        color: #f5efef;
        border-radius: 8px;
        transition: 0.15s all;
        border: 0;
        cursor: pointer;

        &:hover {
            opacity: 70%;
        }
    }
}
</style>
