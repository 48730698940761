<template>
    <div class='form-group'>
        <label :for='id' class='text-label'>{{ label }}</label>

        <div
            class="image-upload"
            :class="{ 'dragging': isDragging }"
            :style="{ aspectRatio: itemBody.aspectRatio ? itemBody.aspectRatio : aspectRatio }"
            @dragover="dragover"
            @dragleave="dragleave"
            @drop="dropImage"
        >
            <img
                v-if="url || value"
                :src="url ? url : value"
                alt="preview"
                class="image-preview"
            />

            <div v-else class="image-default">
                <ImageDefault />
            </div>

            <label
                class="edit-button"
                @mouseenter="() => isTooltipEditShown = true"
                @mouseleave="() => isTooltipEditShown = false"
            >
                <i v-if="url || value" class="fa fa-pencil fa-lg"></i>
                <i v-else class="fa fa-plus fa-lg"></i>
                <div
                    class="tooltip"
                    :class="{
                        'tooltip-shown': isTooltipEditShown,
                        'tooltip-hidden': !isTooltipEditShown,
                    }"
                >
                    <span v-if="url || value">Заменить изображение</span>
                    <span v-else>Добавить изображение</span>
                </div>
                <input type="file" name="image" accept=".png, .jpg, .jpeg" class="hidden" @change="handleInput" />
            </label>

            <!-- <label
                class="title-button"
                @mouseenter="() => isTooltipTitleShown = true"
                @mouseleave="() => isTooltipTitleShown = false"
                @click.prevent="() => isTitleInputShown = !isTitleInputShown"
            >
                <i class="fa fa-bookmark fa-lg"></i>
                <div
                    class="tooltip"
                    :class="{
                        'tooltip-shown': isTooltipTitleShown,
                        'tooltip-hidden': !isTooltipTitleShown,
                    }"
                >
                    <span>Атрибут title</span>
                </div>
            </label>

            <label
                class="alt-button"
                @mouseenter="() => isTooltipAltShown = true"
                @mouseleave="() => isTooltipAltShown = false"
                @click.prevent="() => isAltInputShown = !isAltInputShown"
            >
                <i class="fa fa-tag fa-lg"></i>
                <div
                    class="tooltip"
                    :class="{
                        'tooltip-shown': isTooltipAltShown,
                        'tooltip-hidden': !isTooltipAltShown,
                    }"
                >
                    <span>Alt картинки</span>
                </div>
            </label> -->

            <label
                v-if="url || value"
                class="delete-button"
                @mouseenter="() => isTooltipDeleteShown = true"
                @mouseleave="() => isTooltipDeleteShown = false"
                @click.prevent="handleDeleteImage"
            >
                <i class="fa fa-times fa-lg"></i>
                <div
                    class="tooltip"
                    :class="{
                        'tooltip-shown': isTooltipDeleteShown,
                        'tooltip-hidden': !isTooltipDeleteShown,
                    }"
                >
                    <span>Удалить изображение</span>
                </div>
            </label>

            <span class='validation-error' v-show='error'>{{ error }}</span>
        </div>

        <!-- <div v-if="isTitleInputShown || isAltInputShown" class="additional-fields">
            <div v-if="isAltInputShown">
                <label class='text-label'>Alt картинки</label>
                <input class='form-control' :value='alt' @input='onChangeAlt'>
            </div>

            <div v-if="isTitleInputShown">
                <label class='text-label'>Атрибут title</label>
                <input class='form-control' :value='alt' @input='onChangeTitle'>
            </div>
        </div> -->
    </div>
</template>

<script>
import ImageDefault from '@/components/Images/ImageDefault';
import FormItemMixin from '@/components/FormBuilder/mixins/FormItemMixin';

export default {
    name: 'FormImage',
    mixins: [FormItemMixin],
    components: {
        ImageDefault,
    },
    data() {
        return {
            url: null,
            isDragging: false,
            // isTitleInputShown: false,
            // isAltInputShown: false,
            isTooltipEditShown: false,
            isTooltipDeleteShown: false,
            // isTooltipTitleShown: false,
            // isTooltipAltShown: false,
        }
    },
    props: {
        aspectRatio: {
            default: 3/2,
        },
        // alt: {
        //     default: null,
        // },
        // title: {
        //     default: null,
        // },
    },
    // mounted() {
    //     if (this.alt) this.isAltInputShown = true;
    //     if (this.title) this.isTitleInputShown = true;
    // },
    methods: {
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        dropImage(e) {
            e.preventDefault();

            if (e.dataTransfer) {
                const file = e.dataTransfer.files[0];

                if (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') {
                    this.url = URL.createObjectURL(file);
                    this.onInput(e.dataTransfer.files[0]);
                }
            }

            this.isDragging = false;
        },
        handleInput(e) {
            if (e.target) {
                const file = e.target.files[0];
                this.url = URL.createObjectURL(file);
                this.onInput(e.target.files[0]);
            }
        },
        handleDeleteImage() {
            this.url = null;
            this.isTooltipDeleteShown = false;
            this.onInput(null);
        },
        onInput(value) {
            if (this.validation) {
                const validation = this.validation.validate(value);
                if (!validation.valid) {
                    this.error = validation.reason;
                } else {
                    this.error = null;
                }
            }
            this.$emit('input', value);
        },
        // onChangeAlt(e) {
        //     this.$emit('input-alt', e.target.value);
        // },
        // onChangeTitle(e) {
        //     this.$emit('input-title', e.target.value);
        // },
    }
};
</script>

<style lang='scss' scoped>

.image-upload {
    position: relative;
    border: 3px solid white;
    box-shadow: 0px 4px 20px rgba(#000, .1);

    &.dragging {
        background-color: transparent;
        border: 3px dashed rgb(77, 77, 204);
    }
}
.image-default {
    padding: 5%;
    width: 100%;
    height: 100%;

    svg {
        width: 100%;
        height: 100%;
    }
}

.image-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edit-button,
/* .title-button,
.alt-button, */
.delete-button {
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    margin-bottom: 0;
    background-color: #e5eaee;
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(#000, .1);

    .tooltip {
        position: absolute;
        bottom: 100%;
        right: 8px;
        display: flex;
        align-items: center;
        justify-items: center;
        background-color: white;
        width: 185px;
        margin-bottom: 8px;
        padding: 8px 16px;
        transition: all 0.15s;
        z-index: 10000;
        border-radius: 4px;
        box-shadow: 0px 4px 20px rgba(#000, .1);

        span {
            text-align: center;
            font-size: 12px;
            line-height: 20px;
            color: #798892;
        }

        &.tooltip-shown {
            opacity: 100%;
        }
        &.tooltip-hidden {
            opacity: 0%;
            visibility: hidden;
        }
    }

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #798892;
    }

    &:hover {
        i {
            color: rgb(136, 136, 218);
        }
    }
}

.edit-button {
    top: 0;
    transform: translate(50%, -50%);

    input {
        display: none;
    }
}

.delete-button {
    bottom: 0;
    transform: translate(50%, 50%);
}

/* .title-button {
    top: 0;
    right: 40px;
    transform: translate(50%, -50%);
}

.alt-button {
    top: 0;
    right: 80px;
    transform: translate(50%, -50%);
}

.additional-fields {
    display: flex;
    gap: 30px;
    margin-top: 10px;

    & > div {
        width: calc(50% - 15px);
    }
} */

.validation-error {
    position: absolute;
    color: #fff;
    font-weight: bold;
    right: 5px;
    top: 20px;
    font-size: 12px;
    background: rgba(orange, .7);
    padding: 0 5px;
    border-radius: 5px;
}
.text-label {
    color: rgba(#000, .5);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 12px;
}
</style>
